import React from "react"
import { Link } from "gatsby"
import AppStoreBadge from "./../images/download-badge.svg"

import CKMAPPRO from "./../images/ckm-logo.svg"
import "./footer.css"

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-links">
        <Link to="/datenschutz/">Datenschutzerklärung</Link>
        <Link to="/impressum/">Impressum</Link>
        <Link to="/faq/">FAQ</Link>
      </div>
      <div className="ckm-logo">
        <a
          className="apbadge"
          href="https://apps.apple.com/de/app/id1512968142"
          target="_blank"
        >
          <AppStoreBadge />
        </a>
        <a href="https://www.codekommando.de/">
          <CKMAPPRO />
        </a>
      </div>
    </div>
  )
}

export default Footer
