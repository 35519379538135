import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./../images/lehrerbuch.svg"
import LogoVertical from "./../images/lehrerbuch-vertical.svg"
import ImageBackground from "./images/image-background"
import AppStoreBadge from "./../images/download-badge.svg"

const Header = () => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    <ImageBackground />
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
        alignItems: `center`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <div className="vertical-logo">
            <LogoVertical />
          </div>
          <div className="horizontal-logo">
            <Logo />
          </div>
        </Link>
      </h1>
      <a href="https://apps.apple.com/de/app/id1512968142" target="_blank">
        <AppStoreBadge />
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
